.unit-converter__unit-selectors {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  align-items: flex-end;
}

.unit-converter__results {
  margin-block-start: 1.5rem;
}

.unit-converter__result {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.unit-converter__buttons {
  display: flex;
  gap: .5rem;
  margin-bottom: .5rem;
}

.unit-converter__calculation {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}

@media (max-width: 600px) {
  .unit-converter__unit-selectors {
    flex-direction: column;
  }
}
